import { motion } from 'framer-motion'
import _camelCase from 'lodash.camelcase'
import _startCase from 'lodash.startcase'
import React from 'react'
import styled, { css } from 'styled-components'
import theme from 'styled-theming'
import { FormGroup } from '../styles/forms/Forms.css'
import { colors, device, zIndex } from '../styles/theme'
import CustomSelect from './CustomSelect'
import { customStartCase } from "../utils/customStartCase"

const themedBox = theme('mode', {
  light: css`
    background-color: ${colors.grayscale.light5};
  `,
  dark: css`
    background-color: ${colors.grayscale.light2};
  `,
})
const FilterBox = styled(motion.div)`
  ${themedBox};
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
  z-index: ${zIndex.elevated};
  display: grid;
  grid-column-gap: 2rem;

  @media ${device.md} {
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
  @media ${device.lg} {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
`

const FilterInput = ({ filter, queryParams, setQueryParams }) => {
  const [name, values] = filter
  return (
    <FormGroup>
      <CustomSelect
        aria-label={`Filter inventory by ${name}`}
        handleChange={(e) => setQueryParams({ [e.target.name]: e.target.value })}
        defaultValue={queryParams[name]}
        name={name}
        id={name}
      >
        <option value="" disabled>
          Filter by {_startCase(name)}
        </option>
        {values?.map((val, index) => (
          <option
            aria-label={val}
            key={val + index}
            value={_camelCase(val)}
            dangerouslySetInnerHTML={{ __html: `${name === 'location' ? val : _startCase(val)}` }}
          />
        ))}
      </CustomSelect>
    </FormGroup>
  )
}

const FiltersContainer = ({ filters, queryParams, setQueryParams }) => (
  <FilterBox
    initial={{ y: 100, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -100, opacity: 0 }}
    positionTransition
    id="filters"
  >
    {filters?.map((filter) => (
      <FilterInput queryParams={queryParams} setQueryParams={setQueryParams} key={filter[0]} filter={filter} />
    ))}
  </FilterBox>
)

FiltersContainer.propTypes = {}

export default FiltersContainer
